<template style="display: inline; overflow: hidden">
  <div>
    <label for="text">{{ data.label }}</label>
    <span v-if="isActive" class="setting-icon" @click="openSettings">
      <i class="el-icon-s-tools" />
    </span>
    <div
      v-html="data.content"
      :style="getStyle"
      class="rectangular-block"
    ></div>
  </div>
</template>

<script>
export default {
  name: "DIVView",
  props: ["field", "data", "isActive"],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    getStyle() {
      return (
        `height:${this.data.height - 22}px;width:${this.data.width}px;` +
        this.getElementStyle
      );
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0.4";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "#f9f9f9";
      }
      return borderStyle;
    },
  },
  methods: {
    openSettings() {
      this.$emit("settings");
    },
  },
};
</script>

<style lang="scss">
.rectangular-block {
  background-color: transparent;
  border: 0.4px solid black;
}
</style>
